<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="0"
    offset-y
  >
    <template v-slot:activator="{on, attrs}">
      <div v-bind="attrs" v-on="on">
        <text-dinamico
          v-model="dateSelected"
          :label="labelText"
          :error="!!dateErrorMessage"
          :error-messages="dateErrorMessage"
          readonly
          required
        />
      </div>
    </template>
    <v-date-picker
      v-model="date"
      :value="initialDate"
      show-adjacent-months
      first-day-of-week="1"
      no-title
      :max="max"
      locale="es"
      color="title"
      scrollable
      :active-picker.sync="activePicker"
      @update:active-picker="updateActivePicker"
      @input="onDateSelected"
    />
  </v-menu>
</template>

<script>
import TextDinamico from '@/components/textDinamico.vue';
import dayjs from 'dayjs';

export default {
  name: 'GDatePicker',
  components: {TextDinamico},
  props: {
    max: {
      type: String,
      required: true,
    },
    startType: {
      type: String,
      default: 'YEAR',
    },
    labelText: {
      type: String,
      default: '',
    },
    validationMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      menu: false,
      date: '',
      dateSelected: '',
      dateErrorMessage: '',
      activePicker: '', // Vista inicial del selector
      initialDate: '', // Fecha inicial dentro del rango
    };
  },
  watch: {
    menu(val) {
      if (val) {
        // Si el menú se abre, verifica si la fecha seleccionada es válida o mayor al máximo
        if (
          !this.dateSelected ||
          dayjs(this.dateSelected).isAfter(dayjs(this.max))
        ) {
          this.date = this.max; // Establece la fecha en el valor máximo si no hay ninguna o es inválida
        }
        setTimeout(() => (this.activePicker = this.startType), 10);
      }
    },
    date(val) {
      if (val) {
        this.onDateSelected(val);
      }
    },
    dateSelected(val) {
      if (
        this.validateDateGreaterThanYear(val) &&
        this.validationMessage.length
      ) {
        this.dateErrorMessage = this.validationMessage.replace(
          '$',
          this.calculateYearsFromMax().toString()
        );
      } else {
        this.dateErrorMessage = '';
      }
    },
  },
  created() {
    // Establece la fecha inicial de selección dentro del rango permitido
    this.initialDate = this.calculateInitialDate();
  },
  methods: {
    updateActivePicker(picker) {
      this.activePicker = picker;
    },
    onDateSelected(val) {
      if (this.validateDate(val)) {
        this.dateSelected = val;
        this.$emit('input', val); // Emitimos la fecha válida
      } else {
        this.dateSelected = '';
        this.$emit('input', null); // Emitimos null si la fecha es inválida
      }
    },
    validateDate(val) {
      const regEx = /^\d{4}-\d{2}-\d{2}$/;
      return regEx.test(val); // Validamos formato de fecha
    },
    validateDateGreaterThanYear(val) {
      const selectedDate = dayjs(val);
      const currentDate = dayjs(this.max).subtract(1, 'year');
      return currentDate.isBefore(selectedDate);
    },
    calculateInitialDate() {
      const today = new Date();
      const maxDate = new Date(this.max);
      return today > maxDate ? this.max : today.toISOString().split('T')[0];
    },
    calculateYearsFromMax() {
      const maxDate = dayjs(this.max);
      const currentDate = dayjs();
      return currentDate.diff(maxDate, 'year');
    },
  },
};
</script>

<style scoped></style>
