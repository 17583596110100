<template>
  <v-row
    align="center"
    justify="center"
    style="background-color: white"
    no-gutters
  >
    <vue-headful
      title="Financiamiento - Automotriz Petersen"
      description="¿Necesitas ayuda para Financiar la comprar de tu vehículo? Servicios de Financiamiento Disponibles Aprobación en menos de una hora."
    />

    <div class="mt-6">
      <v-row id="compramos" align="center" justify="center" no-gutters>
        <v-col v-for="(titulo, index) of titulos" :key="index" cols="12">
          <h1
            v-if="$vuetify.breakpoint.smAndDown"
            class="text-center title--text font-weight-light text-h4 mt-12"
          >
            Financiamiento
          </h1>
          <h1 v-else class="text-center title--text font-weight-light text-h3">
            {{ titulo }}
          </h1>
        </v-col>
        <v-col cols="12">
          <h3
            v-for="(title, index) of subtitles"
            :key="index"
            class="text-center title--text font-weight-light my-3"
          >
            {{ title }}
          </h3>
        </v-col>
        <v-col cols="11" md="8" xl="8" lg="8">
          <formulario-petersen-venta />
        </v-col>
      </v-row>
    </div>
    <v-col cols="12">
      <v-row justify="center" no-gutters>
        <v-col cols="12" :xs="12" :sm="12" :md="6" :lg="6" :xl="4" class="my-3">
          <v-row
            align="center"
            :justify="
              $vuetify.breakpoint.smAndDown ? 'center' : 'space-between'
            "
            no-gutters
          >
            <policy />
            <term />
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import FormularioPetersenVenta from '@/components/core/formularioPetersenVenta';
import Policy from '@/components/policy.vue';
import Term from '@/components/term.vue';

export default {
  name: 'Financiamiento',
  components: {Term, Policy, FormularioPetersenVenta},
  data: () => ({
    titulos: ['- Financiamiento -'],
    subtitles: [
      ' ⚠️',
      '- Un préstamo siempre conlleva interés',
      '- Es más barato pedir un crédito bancario',
      '-- Completa los datos para gestionar tu solicitud --',
      '⛔️Con DICOM no se puede optar a crédito⛔️',
    ],
  }),
};
</script>
