export function customRound(value) {
  const integerPart = Math.floor(value);
  const decimalPart = value - integerPart;

  if (decimalPart > 0.01) {
    return integerPart + 1;
  } else {
    return integerPart;
  }
}

export function isValidNumber(num) {
  if (typeof num === 'string' && num !== '') {
    return Number.isFinite(Number(num));
  }

  return Number.isFinite(num);
}
