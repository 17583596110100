<template>
  <v-select
    v-bind="$attrs"
    :color="textColor"
    :placeholder="placeholder"
    :background-color="backgroundColor"
    :class="classDefined"
    :dense="$vuetify.breakpoint.smAndDown"
    :outlined="outlined || isOutline"
    clearable
    :value="value"
    :items="items"
    :loading="loading"
    :rules="required ? basicRule : []"
    hide-selected
    :disabled="isDisabled || items.length === 0"
    v-on="$listeners"
    class="pb-0"
    @click="outlined = true"
    @click:clear="clear"
  >
  </v-select>
</template>

<script>
export default {
  name: 'selector',
  props: {
    value: {
      type: [Object, String],
    },
    placeholder: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: 'white',
    },
    textColor: {
      type: String,
      default: 'info',
    },
    classDefined: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isOutline: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    outlined: false,
    basicRule: [(v) => !!v || 'Campo Requerido'],
  }),
  methods: {
    clear() {
      this.$emit('input', null);
    },
    get() {
      return this.value;
    },
    set(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped></style>
