<template>
  <div>
    <v-form v-model="form" ref="form">
      <v-autocomplete
        v-model="vehiculo"
        :items="vehicles"
        item-text="datos.tituloPrecio"
        item-value="datos.patente"
        clearable
        return-object
        color="info"
        :outlined="!!vehiculo"
        hide-selected
        required
        :rules="[(v) => !!v || 'Campo Requerido']"
        placeholder="¿Qué vehículo te interesa comprar?"
        @blur="displayFieldsOnBlur"
      ></v-autocomplete>
      <v-row v-if="vehicleSelected">
        <v-col cols="6">
          <h4 class="title--text font-weight-light">Pie recomendado 40%</h4>
          <h3>{{ pieRecomend }}</h3>
        </v-col>

        <v-col cols="6">
          <h4 class="title--text font-weight-light">
            Pie minimo {{ minimumPercentage ? minimumPercentage : '30' }}%
          </h4>
          <h3>{{ pieMinimum ? pieMinimum : '-' }}</h3>
        </v-col>
      </v-row>
      <text-dinamico
        v-model="renta"
        :required="true"
        is-renta
        type="number"
        placeholder="Ingreso líquido"
        @blur="displayFieldsOnBlur"
      />

      <h2
        v-if="pieRecomend"
        style="text-align: center"
        class="title--text font-weight-light"
      >
        Pie que desea entregar
      </h2>
      <text-dinamico
        ref="textDinamicoPie"
        v-if="pieRecomend"
        v-model="pie"
        :key="`pie${vehiculo}`"
        :required="true"
        placeholder="Pie"
        type="number"
        :new-rules="pieRules"
        @blur="displayFieldsOnBlur"
      />

      <v-row v-if="recommendOtherVehicles && displayRecommend" class="my-2">
        <v-col cols="12">
          <v-row align="center" justify="center" no-gutters>
            <h4 class="title--text font-weight-light">
              Te recomendamos vehiculos hasta
              {{ precioFix(recommendOtherVehicles) }}
            </h4>
          </v-row>
        </v-col>
        <v-row align="center" justify="center" no-gutters>
          <v-btn color="info" @click="openVehicles"
            >Ver vehículos recomendados
          </v-btn>
        </v-row>
      </v-row>
      <v-row v-if="allowQuota" no-gutters>
        <text-dinamico
          v-model="quoteRecommended"
          required
          type="number"
          label="Cuota que espera pagar"
          :new-rules="quoteRules"
        >
        </text-dinamico>
      </v-row>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
          <text-dinamico
            v-model="rut"
            :required="true"
            :new-rules="[(v) => /^\d{7,8}-[0-9kK]$/.test(v) || 'Rut Invalido']"
            placeholder="Rut"
            hint="El rut debe tener el formato 11111111-1"
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
          <text-dinamico
            v-model="telefono"
            :required="true"
            :new-rules="[
              (v) =>
                (v && v.length >= 12) ||
                'El Teléfono debe tener al menos 12 caracteres',
              (v) =>
                /^(?:\+?56)?(?:\s?)(?:9\d{8}|2\d{7}|[3-4]\d{7}|[5-7]{1}[2-9]{1}\d{6})$/.test(
                  v
                ) || 'Telefono Invalido',
            ]"
            hint="El telefono debe tener el formato +56912345678"
            placeholder="Teléfono"
          />
        </v-col>
      </v-row>
      <auto-completar
        v-if="Boolean(telefono && telefono.length === 12)"
        v-model="contact"
        placeholder="Contacto de preferencia"
        :disabled="!contacts.length"
        required
        :items="contacts"
      />
      <text-dinamico
        v-model="nombre"
        :required="true"
        :new-rules="[
          (v) =>
            (v && v.length >= 2) ||
            'El Nombre debe tener al menos 2 caracteres',
          (v) => /^[a-zA-Z\s]+$/.test(v) || 'El Nombre debe ser solo letras',
        ]"
        placeholder="Nombres"
      />
      <text-dinamico
        v-model="apellido"
        :required="true"
        :new-rules="[
          (v) =>
            (v && v.length >= 2) ||
            'El Apellido debe tener al menos 2 caracteres',
          (v) => /^[a-zA-Z\s]+$/.test(v) || 'El Apellido debe ser solo letras',
        ]"
        placeholder="Apellidos"
      />
      <g-date-picker
        v-model="dateOfBirth"
        :max="maxDate"
        label-text="Fecha de nacimiento"
      />
      <auto-completar
        v-model="selectedNacionalidad"
        label="Nacionalidad"
        required
        :items="nacionalities"
        placeholder="Nacionalidad"
      ></auto-completar>

      <text-dinamico
        v-model="correo"
        :required="true"
        :isCorreo="true"
        placeholder="Correo Electronico"
      />
      <selector
        v-model="selectedActivity"
        required
        is-renta
        :items="['Dependiente', 'Independiente']"
        placeholder="Tipo de actividad"
      />
      <text-dinamico
        v-if="selectedActivity === 'Dependiente'"
        v-model="employer"
        required
        placeholder="Nombre del empleador"
      ></text-dinamico>
      <v-col v-if="selectedActivity !== ''" cols="12" class="pa-0">
        <g-date-picker
          v-model="dateSelected"
          :max="maxLaborDate"
          :label-text="
            selectedActivity === 'Independiente'
              ? 'Fecha de inicio de actividades'
              : 'Fecha de ingreso laboral'
          "
          validation-message="Antigüedad laboral mínima es $ año"
        />
      </v-col>
    </v-form>
    <v-col cols="12" class="mb-12">
      <v-row align="center" justify="center" no-gutters>
        <v-btn
          color="info"
          @click="cotizar"
          :loading="loading"
          :disabled="overFinancing || !pie"
        >
          Cotizar
        </v-btn>
      </v-row>
    </v-col>
    <v-dialog v-model="snackbar" width="410">
      <v-card>
        <v-card-title class="text-h5 info--text">
          <v-row
            align="center"
            justify="center"
            no-gutters
            class="font-weight-bold"
          >
            ¡Gracias por su tiempo!
          </v-row>
        </v-card-title>
        <v-card-text class="mt-3 text-subtitle-1 text-justify">
          Ya recibimos y comenzamos a gestionar tu solicitud a la financieras en
          convenio. <br />
          Este proceso puede tardar un par de horas, sin embargo, pronto te
          contactaremos para saludarte, confirmar los datos y responder
          cualquier inquietud que tengas.
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TextDinamico from '@/components/textDinamico';
import {findClosestValue, isWeb} from '@/utils/general';
import {mapActions, mapMutations, mapState} from 'vuex';
import {precioFix} from '@/utils/vehiculo';
import dayjs from 'dayjs';
import Selector from '@/components/core/selector.vue';
import AutoCompletar from '@/components/core/autoCompletar.vue';
import {customRound, isValidNumber} from '@/utils/financing';
import GDatePicker from '@/components/core/GDatePicker.vue';

export default {
  name: 'formularioPetersenVenta',
  components: {GDatePicker, AutoCompletar, Selector, TextDinamico},
  data: () => ({
    dateOfBirth: null,
    menu: false,
    contacts: ['💬 Whatsapp', '📱 Teléfono'],
    contact: null,
    menu2: false,
    displayRecommend: false,
    minimumPercentage: null,
    minimumPercentageWithoutRound: null,
    minimumValue: null,
    displayFields: false,
    nacionalities: [
      'chilena',
      'venezolana',
      'colombiana',
      'peruana',
      'ecuatoriana',
      'haitiana',
      'Otra',
    ],
    startType: 'YEAR',
    dateMessage: '',
    activePicker: '',
    dateSelected: '',
    loading: false,
    snackbar: false,
    quoteRecommended: null,
    employer: '',
    overFinancing: false,
    maxFinancing: false,
    naturalPerson: true,
    iconMensaje: '',
    selectedAntiguedad: null,
    snackbarMensaje: '',
    selectedNacionalidad: '',
    selectedCuenta: '',
    selectedFala: '',
    selectedActivity: '',
    nombre: '',
    apellido: '',
    correo: '',
    marca: '',
    modelo: '',
    renta: '',
    kilometraje: null,
    ano: null,
    version: '',
    combustible: '',
    form: false,
    rut: '',
    pie: '',
    pieRecomend: 0,
    pieMinimum: 0,
    vehiculo: '',
    telefono: '+569',
    vehicleSelected: null,
  }),
  computed: {
    ...mapState(['stockGeneral', 'correoSend', 'precios']),
    pieRules() {
      return [
        (v) =>
          parseInt(v, 10) >= this.minimumValue ||
          `El pie minimo es ${this.pieMinimum}`,
        (v) => !!v || 'Campo Requerido',
        (v) =>
          this.vehicleSelected.datos.Precio_de_Venta >= v ||
          'Pie no puede ser mayor al precio del vehiculo',
      ];
    },
    quoteRules() {
      return [
        (v) => !!v || 'Campo Requerido',
        (v) => String(v).length >= 5 || 'La cuota debe ser superior a $99.999',
        (v) =>
          String(v).length <= 8 || 'La cuota debe ser inferior a $10.000.000',
      ];
    },
    allowQuota() {
      if (!this.pie) return false;
      if (this.pie < this.vehicleSelected.datos.Precio_de_Venta * 0.3)
        return false;
      return this.vehicleSelected.datos.Precio_de_Venta >= this.pie;
    },
    maxDate() {
      const date = dayjs().subtract(21, 'year');
      return date.format('YYYY-MM-DD');
    },
    maxLaborDate() {
      const date = dayjs().subtract(1, 'year');
      return date.format('YYYY-MM-DD');
    },
    vehicles() {
      if (!this.stockGeneral || !this.stockGeneral.length) return [];
      return this.stockGeneral.filter((auto) => {
        const arr = auto.datos.status.split(' ');

        arr.shift();

        const status = arr.join(' ');

        return status !== 'RESERVADO' && status !== 'VENTA EN PROCESO';
      });
    },
    recommendOtherVehicles() {
      const {
        caseB,
        caseC,
        caseD,
        calculatePieSecond,
        calculateThirdValue,
        getMaxValue,
        precios,
      } = this;

      if (caseD) {
        return 0;
      }

      if (caseC) {
        return calculatePieSecond;
      }

      if (caseB) {
        return calculateThirdValue;
      }

      if (getMaxValue < findClosestValue(getMaxValue, precios)) {
        return 0;
      }

      return getMaxValue;
    },
    caseA() {
      const {
        calculateFirstValue,
        vehicleSelected,
        pie,
        minimumValue,
        renta,
      } = this;

      if (!vehicleSelected) return false;

      const rentaFix = parseInt(renta);

      return (
        calculateFirstValue < vehicleSelected.datos.Precio_de_Venta &&
        pie < minimumValue &&
        pie > rentaFix
      );
    },
    caseB() {
      const {calculateFirstValue, vehicleSelected, pie, minimumValue} = this;

      if (!vehicleSelected) return false;

      return (
        calculateFirstValue > vehicleSelected.datos.Precio_de_Venta &&
        pie < minimumValue
      );
    },
    caseC() {
      const {
        calculateFirstValue,
        vehicleSelected,
        pie,
        minimumValue,
        renta,
      } = this;

      const rentaFix = parseInt(renta);
      return (
        pie < minimumValue &&
        calculateFirstValue < vehicleSelected.datos.Precio_de_Venta &&
        pie > rentaFix
      );
    },
    caseD() {
      const {calculateFirstValue, vehicleSelected, pie, minimumValue} = this;
      if (!pie) return false;

      return (
        pie > minimumValue &&
        calculateFirstValue > vehicleSelected.datos.Precio_de_Venta
      );
    },
    calculateFirstValue() {
      const {vehicleSelected, renta} = this;
      if (!vehicleSelected) return 0;
      return (parseInt(renta) * 8) / 0.7;
    },
    getMaxValue() {
      const {calculatePieFirst, calculatePieSecond} = this;

      return Math.max(calculatePieFirst, calculatePieSecond);
    },
    calculatePieFirst() {
      const {pie} = this;
      if (!pie) return 0;
      return parseInt(pie) / 0.34;
    },
    calculatePieSecond() {
      const {pie} = this;

      if (!pie) return 0;

      return parseInt(pie) * 2.5;
    },
    calculateThirdValue() {
      const {pie} = this;
      if (!pie) return 0;

      return parseInt(pie) / 0.3;
    },
  },
  watch: {
    async rut(val) {
      if (val && val.length >= 8 && !val.includes('-')) {
        this.rut = this.completeRut(val);
      }
    },
    dateSelected(val) {
      if (this.validateDateGreaterThanYear(val)) {
        this.dateMessage = 'Antigüedad laboral mínima es 1 año';
      } else {
        this.dateMessage = '';
      }
    },
    menu(val) {
      if (val) {
        setTimeout(() => (this.activePicker = this.startType), 10);
      }
    },
    date(val) {
      this.dateSelected = null;
      if (val) {
        this.dateSelected = val;
      }
    },
    birth(val) {
      if (val) {
        this.dateOfBirth = val;
      }
    },
    correoSend(val) {
      if (val) {
        this.loading = false;
        this.snackbar = true;
        this.snackbarMensaje = 'Financiamiento Enviado';
        this.iconMensaje = 'mdi-check';
        this.SET_CORREOSEND(false);
      }
    },
    stockGeneral: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.length) {
          const {
            $route: {
              query: {vehicle},
            },
          } = this;

          if (vehicle) {
            const search = val.find(
              (auto) => auto.id.toString() === vehicle.toString()
            );
            if (search) {
              this.vehiculo = search;
              this.vehicleSelected = search;
              this.pieRecomend = precioFix(
                parseInt(search.datos.Precio_de_Venta) * 0.4
              );
              this.pieMinimum = precioFix(
                parseInt(search.datos.Precio_de_Venta) * 0.3
              );
            }
          }
        }
      },
    },
  },
  methods: {
    ...mapActions(['SEND_MAIL', 'SEND_BUY_GENIO']),
    ...mapMutations(['SET_CORREOSEND']),
    validateDateGreaterThanYear(date) {
      const selectedDate = dayjs(date);
      const currentDate = dayjs().subtract(1, 'year');

      return currentDate.isBefore(selectedDate);
    },
    customRound,
    isWeb,
    precioFix,
    isValidNumber,
    calculatePrice() {
      const vehicle = this.stockGeneral.find(
        (auto) => auto.id === this.vehiculo.id
      );
      if (this.renta < 0 || !isValidNumber(this.renta)) {
        this.renta = null;
      }
      if (this.pie < 0 || !isValidNumber(this.pie)) {
        this.pie = null;
      }
      this.vehicleSelected = vehicle;

      const lista = parseInt(vehicle.datos.Precio_de_Venta);
      this.pieRecomend = precioFix(lista * 0.4);

      const percentage = (lista - parseInt(this.renta) * 8) / lista;
      const totalPercentage = percentage * 100;
      const amount = Math.round(totalPercentage);

      if (amount > 40) {
        this.pieMinimum = this.pieRecomend;
        this.minimumPercentage = 40;
        this.minimumValue = 0.4 * lista;
      } else if (amount < 20) {
        this.pieMinimum = precioFix(0.3 * lista);
        this.minimumValue = 0.3 * lista;
        this.minimumPercentage = 30;
      } else {
        this.minimumValue = (amount / 100) * lista;
        this.pieMinimum = precioFix((amount / 100) * lista);
        this.minimumPercentage = amount;
      }

      this.overFinancing = !!(
        isValidNumber(this.pie) && this.pie < this.minimumValue
      );
      if (this.pie) {
        this.$refs.textDinamicoPie.validate();
      }
      const {caseD} = this;

      if (this.pie) {
        this.displayRecommend = !caseD;
      }
    },
    openVehicles() {
      this.$router.push({
        name: 'Stock',
        query: {
          maxPrice: this.recommendOtherVehicles,
        },
      });
    },
    cotizar() {
      if (this.overFinancing) return;
      const allowCotizar =
        !this.form ||
        this.dateSelected === '' ||
        this.dateMessage !== '' ||
        this.selectedActivity === '' ||
        (this.employer === '' && this.selectedActivity === 'Dependiente');

      if (allowCotizar) {
        this.$refs.form.validate();
        return;
      }
      const mail = {
        nombre: this.nombre,
        apellido: this.apellido,
        rut: this.rut,
        telefono: this.telefono,
        correo: this.correo.toLowerCase(),
        renta: this.renta,
        birth: this.dateOfBirth,
        employer: this.employer,
        antiguedad: this.dateSelected,
        nacionalidad: this.selectedNacionalidad,
        vehiculo: this.vehiculo.id,
        quota: this.quoteRecommended,
        naturalPerson: this.naturalPerson,
        pie: this.pie,
        activity: this.selectedActivity,
        contact: this.contact,
        url: 'automotrizpetersen.cl' + this.$route.path,
        tipo: 'venta',
      };

      this.loading = true;
      this.SEND_MAIL(mail);
      this.SEND_BUY_GENIO(mail);
      this.$refs.form.reset();
    },
    displayFieldsOnBlur() {
      this.calculatePrice();
      this.displayFields = !!this.renta;
    },
    async getRutInfo(rut) {
      if (!rut || !rut.length) return;
      const url = `https://api.libreapi.cl/rut/activities?rut=${rut}`;

      const response = await fetch(url, {
        method: 'GET',
      });
      const {data} = await response.json();
      return data;
    },
    completeRut(rut) {
      let multiplier = 0;
      let sum = 1;

      let tempRut = rut;
      while (tempRut) {
        sum = (sum + (tempRut % 10) * (9 - (multiplier++ % 6))) % 11;
        tempRut = Math.floor(tempRut / 10);
      }

      let verificationDigit = sum ? sum - 1 : 'k';

      return rut + '-' + verificationDigit;
    },
  },
};
</script>

<style scoped></style>
